// ** Import Functions
import { createParamsListForTable } from '@src/utility/helpFunctions'

// Server config
let serverConfig = false

if (process.env.REACT_APP_NODE_ENV === 'development') {
   serverConfig = {
      path: 'http://167.99.252.5:8080',
      api:  'http://167.99.252.5:8080/api/core/v1'
   }
}
if (process.env.REACT_APP_NODE_ENV === 'production') {
   serverConfig = {
      path: 'http://209.38.252.69:8080',
      api:  'http://209.38.252.69:8080/api/core/v1'
   }
}

// Endpoints list
const endPoints = {
   getCompaniesList: '/companies',
   addCompany: '/companies',
   getCompany: '/companies/{value}',
   activateCompany: '/companies/{value}',

   getSubscriptionsList: '/subscription-plans',
   getSubscription: '/subscription-plans/{value}',
   addSubscription: '/subscription-plans',
   updateSubscription: '/subscription-plans/{value}',

   getCompanySubscriptionsList: '/subscriptions',
   getCompanySubscriptions: '/subscriptions/{value}',   
   updateCompanySubscriptionActive: '/subscriptions/{value}/manage-active',
   updateCompanySubscriptionPaid: '/subscriptions/periods/{value}/pay',
   updateCompanySubscription: '/subscriptions/{value}'
}

export const ttl_delay = 10// mins

export const checkTtlExpired = (ttl) => {
   const result = Math.ceil((new Date().getTime() - ttl.getTime()) / (1000 * 60 * 1))
   return result > ttl_delay
}

export const apiLink = (endPoint, params = null, clear_params = true) => {

   const uri = params !== null ? createParamsListForTable(params, clear_params) : ''
   if (typeof endPoint === 'object') {
      if (endPoint.values !== undefined) {

         let path = endPoints[endPoint.name]

         endPoint.values.map((item, index) => {
            path = path.replace(`{value${index + 1}}`, item)
         })

         return serverConfig.api + path + uri
      }
      return serverConfig.api + endPoints[endPoint.name].replace('{value}', endPoint.value) + uri
   } else {
      return serverConfig.api + endPoints[endPoint] + uri
   }

}

export { serverConfig }

export default apiLink