// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getSubscriptionsList = createAsyncThunk('appSubscriptions/getSubscriptionsList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getSubscriptionsList', params))

   return {
      params,
      data: response.data.subscriptions,
      totalPages: response.data.total_elements
   }
})

export const getSubscription = createAsyncThunk('appSubscriptions/getSubscription', async id => {
  const response = await axios.get(apiLink({name: 'getSubscription', value: id}))
  return response.data
})

export const addSubscription = createAsyncThunk('appSubscription/addSubscription', async (data) => {
  const response = await axios.post(apiLink('addSubscription'), data)
  return response.data
})

export const updateSubscription = createAsyncThunk('appSubscription/updateSubscription', async (data) => {
  const id = data.id
  delete data.id
  const response = await axios.put(apiLink({name: 'updateSubscription', value: id}), data)
  return response.data
})
export const updateCompanySubscription = createAsyncThunk('appSubscription/updateCompanySubscription', async (data) => {
  const id = data.id
  delete data.id
  const response = await axios.put(apiLink({name: 'updateCompanySubscription', value: id}), data)
  return response.data
})

export const getCompanySubscriptionsList = createAsyncThunk('appSubscriptions/getCompanySubscriptionsList', async params => {
  params.page = params.page === 0 ? 0 : params.page - 1
  const response = await axios.get(apiLink('getCompanySubscriptionsList', params))

  return {
     params,
     data: response.data.subscriptions,
     totalPages: response.data.total_elements
  }
})

export const getCompanySubscriptions = createAsyncThunk('appSubscriptions/getCompanySubscriptions', async id => {
  const response = await axios.get(apiLink({name: 'getCompanySubscriptions', value: id}))
  return response.data
})

export const updateCompanySubscriptionActive = createAsyncThunk('appCompanies/updateCompanySubscriptionActive', async (data) => {
  const response = await axios.put(apiLink({name: 'updateCompanySubscriptionActive', value: data.id}, {active: data.active}))
  return response.data
})
export const updateCompanySubscriptionPaid = createAsyncThunk('appCompanies/updateCompanySubscriptionPaid', async (data) => {
  const response = await axios.put(apiLink({name: 'updateCompanySubscriptionPaid', value: data.id}, {paid: data.paid}))
  return response.data
})

export const appSubscriptionsSlice = createSlice({
  name: 'appSubscriptions',
  initialState: {
    data: [],    
    total: 1,
    params: {},    
    selectedSubscription: null,
    selectedCompanySubscription: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSubscriptionsList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.selectedSubscription = action.payload
      })
      .addCase(addSubscription.fulfilled, (state, action) => {
        state.selectedSubscription = action.payload
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.selectedSubscription = action.payload
      })
      .addCase(getCompanySubscriptionsList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })      
      .addCase(getCompanySubscriptions.fulfilled, (state, action) => {
        state.selectedCompanySubscription = action.payload
      })      
  }
})

export default appSubscriptionsSlice.reducer
