// ** Third Party Components
import toast from 'react-hot-toast'

export const quickToast = (message, colors, type = 'success') => {

   colors = !colors ? {
      danger: {
         main: '#F44336'
      }
   } : colors

   const settings = {
      style: {
         padding: '16px',
         color: colors[type].main,
         border: `1px solid ${colors[type].main}`
      },
      iconTheme: {
         primary: colors[type].main
      }
   }

   switch (type) {
      case 'success':
         return toast.success(message, settings)
      case 'warning':
         return toast.warning(message, settings)
      case 'danger':
         return toast.error(message, settings)
   }

}
