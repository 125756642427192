// ** Reducers Imports
import auth from './authentication'
import navbar from './navbar'
import layout from './layout'
import companies from '@src/views/companies/store'
import subscriptions from '@src/views/subscriptions/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  companies,
  subscriptions
}

export default rootReducer
